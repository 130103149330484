import { Text, Input, Form, Grid, Button, Dropzone } from "@nextui-org/react"
import { Box } from "./Box.js"

export const Content = () => (
  <Box css={{px: "$12", mt: "$8", "@xsMax": {px: "$10"}}}>
    <Grid.Container gap={2}>
        <Grid xs={12}>
        <Text h2>Generate Cover Letter</Text>
        </Grid>
        <Grid xs={12}>
            <form onSubmit={"#"}>
                <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ border: '1px dashed white', borderRadius: '10px' }}>
                        <input style={{padding: '40px 0px 40px 40px' }} type="file" accept=".pdf" onChange={"#"} />
                    </div>
                </Grid>
                <Grid xs={12}>
                    <Input label="Company" placeholder="company" onChange={"#"} />
                </Grid>
                <Grid xs={12}>
                    <Input label="Job Position" placeholder="position" onChange={"#"} />
                </Grid>
                <Grid xs={12}>
                    <Button type="submit">Generate</Button>
                </Grid>
            </form>
        </Grid>
    </Grid.Container>
  </Box>
);